:root {
  --animate-delay: .5s;
}

html {
  scroll-behavior: smooth;
}

.white-content {
  background: #fff;
  color: #1B1C1F;
}

.App {
  max-width: 870px;
  margin: 0 auto;
  font-family: mr-eaves-xl-modern, sans-serif;
  padding-bottom: 150px;
}

.theme-btn {
  background: none;
  border: 0;
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 22px;
  color: #e3e5ed;
  cursor: pointer;
}

.white-content .theme-btn {
  color: #ff9a00;
}


.nav-container {
  position: fixed;
  bottom: 150px;
  right: 190px;
}

.nav-container a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
}

.white-content .nav-container a {
  color: #1c1c1c;
}

.nav-container ul {
  list-style: none;
}

.nav-container ul li {
  margin-bottom: 10px;
}

.confirm-email {
  background-color: #309d30;
  padding: 27px 16px;
  width: 440px;
  margin: 0 auto;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  margin-top: 22px;
}

.email-error {
  background-color: #E54C4C;
  color: #fff;
  padding: 19px 20px;
  margin: 0 auto;
  width: 300px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.header-name {
  align-self: center;
}

.header-name h1 {
  font-size: 68px;
  font-weight: 700;
  font-family: mr-eaves-xl-modern, sans-serif;
}

#wave {
  position: relative;
  height: 70px;
  width: 600px;
  background: #e0efe3;
}

#wave:before {
        content: "";
        display: block;
        position: absolute;
        border-radius: 100% 50%;
        width: 340px;
    height: 80px;
    background-color: white;
    right: -5px;
    top: 40px;
}

#wave:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 100% 50%;
        width: 300px;
    height: 70px;
    background-color: #e0efe3;
    left: 0;
    top: 27px;
}

.description {
  max-width: 600px;
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 120px;
}

.description h2 {
  font-size: 18px;
  line-height: 1.7;
  font-weight: 100;
}

.skills {
  font-size: 70px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-top: 50px;
}

.projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  cursor: pointer;
  transition: transform 1s;
  color: white;
  text-decoration: none;
}

.white-content .projects-container {
  color: #1c1c1c;
}

.white-content .project-container:hover {
  box-shadow: 0 0 20px rgba(173, 173, 173, 0.363);
  border-radius: 18px;
  transform: scale(1.02);
}

.white-content .projects-container a {
  color: #1c1c1c;
  border: 1px solid #1c1c1c;
  border-radius: 19px;
}


.white-content .projects-container span a {
  border: 0;
}

.white-content .project ul li {
  color: #1c1c1c;
  text-shadow: none;
}

.white-content input[type="text"], .white-content .messagebox {
  background-color: #fff;
}
.projects-container a {
  text-decoration: none;
  color: white;
}

.project-container:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  border-radius: 18px;
  transform: scale(1.02);
}

.project {
  position: relative;
  background-color: rgb(255, 255, 255, .17);
  border-radius: 0 0 18px 18px;
  padding: 15px 20px;
  margin-top: -3px;
  height: 133px;
}

.project h2 {
  margin-top: 0;
  margin-bottom: 5px;
  display: inline-block;
}

.project span {
  display: inline-block;
  margin-left: 12px;
  font-size: 12px;
  position: absolute;
  top: 18px;
  right: 17px;
}

.project span a {
  margin-left: 7px;
  color: #fff;
}

.project p {
  margin-top: 4px;
  margin-bottom: 7px;
  line-height: 1.2;
}

.project a {
  font-size: 22px;
}

.project ul {
  margin: 0;
  padding: 0;
  display: block;
  margin-bottom: 8px;
}

.project ul li {
  list-style-type: none;
  color: #999;
  display: inline-block;
  margin-right: 6px;
  text-shadow: 0 1px 0 #2e2e2e;
}

.contact-container {
  margin-top: 80px;
  display: block;
}

input[type="text"] {
  width: 50%;
  height: 60px;
  border: 0;
  background-color: #414245;
  border: 1px solid #414245;
  border-radius: 18px;
  margin-bottom: 20px;
  padding-left: 25px;
  font-size: 22px;
  color: #fff;
  box-sizing: border-box;
  display: block;
  font-family: mr-eaves-xl-modern, sans-serif;
}

input[type="text"]:focus {
  background-color: #1B1C1F;
  border: 1px solid #4C73E5;
  outline: none;
}

.messagebox {
  width: 100%;
  border: 0;
  background-color: #414245;
  border: 1px solid #414245;
  border-radius: 18px;
  font-size: 18px;
  font-family: mr-eaves-xl-modern, sans-serif;
  padding-left: 25px;
  padding-top: 20px;
  resize: none;
  font-size: 22px;
  color: #fff;
  box-sizing: border-box;
}

.messagebox:focus {
  background-color: #1B1C1F;
  border: 1px solid #4C73E5;
  outline: none;
}

.submit-button {
  display: block;
  background-color: #396cff;
  color: #fff;
  border: 0;
  padding: 10px;
  float: right;
  font-family: mr-eaves-xl-modern, sans-serif;
  font-size: 18px;
  margin-top: 20px;
  border-radius: 11px;
  padding: 15px 25px;
  font-weight: bold;
  cursor: pointer;
}

.invisible {
  display: none;
}

.visible {
  display: block;
}

.resume-page {
  max-width: 900px;
  margin: 0 auto;
  font-family: mr-eaves-xl-modern,sans-serif;
  margin-top: 40px;
  margin-bottom: 60px;
}

.resume-page a {
  color: #fff;
  font-weight: bold;
}

.resume-container {
  background: white;
  color: black;
  border-radius: 25px;
  min-height: 400px;
  padding: 30px 30px 40px 30px;
  box-sizing: border-box;
  margin-top: 15px;
}

.resume-header {
  display: flex;
  justify-content: space-between;
}

.resume-header a {
  margin-top: 8px;
}

.resume-container a {
  color: #000;
  font-weight: 500;
}

.resume-container h1 {
  margin: 0;
}

.resume-container h2 {
  margin-bottom: 0;
}

.resume-container h3 {
  margin: 12px 0 0 0; 
}

.resume-container h4 {
  margin: 3px 0 0 0;
}

.resume-container ul {
  margin-top: 6px;
  line-height: 1.28;
}
@media only screen and (max-width: 1400px) {
  .nav-container {
    position: fixed;
    bottom: 50px;
    right: 90px;
  }
}

@media only screen and (max-width: 1180px) {
  .nav-container {
    display: none;
  }
}

@media only screen and (max-width: 870px) {
  .App {
    padding: 30px;
  }

  .header-container {
    display: block;
  }

  .header-container h1 {
    text-align: center;
  }

  .header-container img {
    margin: 0 auto;
    display: block;
  }

  .projects-container {
    grid-template-columns: 1fr;
  }
}