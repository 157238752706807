@import url('https://fonts.googleapis.com/css2?family=Rubik&family=Space+Mono&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1B1C1F;
  color: #fff;
  font-family: 'Space Mono', 'Courier New', Courier, monospace;
}

h1, h2, h3 {
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
